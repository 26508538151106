<div class="input-area" [class.longText]="inline && message?.title?.length > 100">
  <div class="answer-title" *ngIf="inline && message?.title?.length > 100">
    <mat-icon aria-hidden="true">info</mat-icon>
    <p [innerHTML]="message.title | sanitizeHtml"></p>
  </div>
  <mat-form-field appearance="fill" *ngIf="!message.responseRequest?.params?.autocomplete"
    [class.required]="control.hasValidator(requiredValidator)">
    <mat-label *ngIf="inline && message?.title?.length > 100">{{
      'common.label.answer' | translate
      }}</mat-label>
    <mat-label *ngIf="!inline || message?.title?.length <= 100"
      [innerHTML]="message.title | translate | sanitizeHtml"></mat-label>
    <input [placeholder]="message.responseRequest.placeholder || null"
      [type]="mapQuestionType(message.responseRequest.type)" matInput [formControl]="control" *ngIf="
            control &&
            message.responseRequest.type !== 'number' &&
            !message.responseRequest?.params?.allowMultiline?.value
          " (keydown.enter)="
            value && !control.errors ? answered.emit(value) : null
          " [attr.inputmode]="inputMode" #inputRef />
    <textarea [placeholder]="message.responseRequest.placeholder || null" matInput rows="5" wrap="hard"
      [formControl]="control" *ngIf="
            mapQuestionType(message.responseRequest.type) === 'text' &&
            message.responseRequest?.params?.allowMultiline?.value
          " #inputRef></textarea>
    <input [placeholder]="message.responseRequest.placeholder || null" matInput type="number" [formControl]="control"
      *ngIf="control && message.responseRequest.type === 'number'" (keydown.enter)="
            value && !control.errors ? answered.emit(value) : null
          " [attr.inputmode]="inputMode" #inputRef />
  </mat-form-field>
  <mat-form-field appearance="fill" *ngIf="message.responseRequest?.params?.autocomplete">
    <mat-label *ngIf="!inline || message?.title?.length <= 100" [innerHTML]="message.title | sanitizeHtml"></mat-label>
    <mat-label *ngIf="inline && message?.title?.length > 100">{{
      'common.label.answer' | translate
      }}</mat-label>
    <input matInput [formControl]="control" [matAutocomplete]="auto" [attr.inputmode]="inputMode" #inputRef />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="
              let option of message.responseRequest?.params?.possibleValues
            " [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <span class="error-message" *ngIf="control.errors && control.value?.length > 0" role="alert" aria-live="assertive">{{ control.errors.message | translate
    }}</span>
</div>
<button mat-raised-button color="primary" *ngIf="
        !inline &&
        (!message.responseRequest?.params?.options ||
          message.responseRequest.params?.options?.length === 0)
      " (click)="answered.emit(value)" [disabled]="emptyValue || control.errors" [attr.aria-label]="'aria.label.continue' | translate">
  {{ 'common.button.continue' | translate }}
</button>
