/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface WebHook { 
    additional_headers?: any | null;
    created_at?: string;
    created_by?: string;
    description?: string;
    event_type?: string;
    filter?: string;
    id?: string;
    method?: WebHook.MethodEnum;
    url?: string;
}
export namespace WebHook {
    export type MethodEnum = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
    export const MethodEnum = {
        Get: 'GET' as MethodEnum,
        Post: 'POST' as MethodEnum,
        Put: 'PUT' as MethodEnum,
        Patch: 'PATCH' as MethodEnum,
        Delete: 'DELETE' as MethodEnum
    };
}


