<ng-container *ngIf="readonly; else editTemplate">
  <textarea #editorContainer id="main-doc-content" [attr.aria-label]="'aria.label.documentContent' | translate"></textarea>
</ng-container>
<ng-template #editTemplate>
  <div class="condition-edit-popover" [class.open]="!!currentCondition"
    [class.offsetY]="conditionEditPopupPosition === 'above'" (pointerdown)="$event.stopPropagation()"
       aria-live="polite" [attr.aria-label]="'aria.label.conditionEditingPopup' | translate">
    <app-condition-edit *ngIf="currentCondition" [dataSource]="dataSource" [parent]="this"
      [currentCondition]="currentCondition"></app-condition-edit>
  </div>
  <textarea #editorContainer id="main-doc-content" [attr.documentContentEditor]="'aria.label.' | translate"></textarea>
  <div class="floating-help-orb" [style.left]="orbPos.x + 'px'" [style.top]="orbPos.y + 'px'"
    [matTooltip]="'document.conditional.addToParagraph' | translate" [class.open]="isOrbOpen"
    (click)="addConditionToSelection()" role="button" [attr.aria-label]="'aria.label.addConditionToParagraph' | translate">
    <mat-icon aria-hidden="true">account_tree</mat-icon>
  </div>
  <button mat-icon-button #cursorPosFollower class="cursorPosFollower" [attr.aria-label]="'aria.label.cursorPositionFollower' | translate"></button>
</ng-template>
<ng-container #tagInputContainer></ng-container>
