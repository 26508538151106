<ng-container *ngIf="!outOfView">
  <div class="node-ribbon" *ngIf="!isInForm">
    <span class="node-type">{{
      "node.names." + logicRef.typeName | translate
      }}</span>
    <div class="node-opts" [style.color]="!useLightText ? null : backgroundColor">
      <mat-icon [attr.aria-label]="'aria.label.minimizeNode'| translate" (click)="minimize()" *ngIf="minimizable">minimize</mat-icon>
      <mat-icon [attr.aria-label]="'aria.label.deleteNode'| translate" (click)="delete()">delete</mat-icon>
    </div>
  </div>
</ng-container>
<button mat-icon-button class="node-settings-button" [matMenuTriggerFor]="nodePropertiesMenu"
  *ngIf="logicRef?.properties && !outOfView && availableProperties?.length" [attr.aria-label]="'aria.label.openNodeSettings'| translate">
  <mat-icon>settings</mat-icon>
</button>
<mat-menu #nodePropertiesMenu="matMenu">
  <button mat-menu-item (click)="onPropertySwitch($event, property)" *ngFor="let property of availableProperties">
    <mat-icon>{{
      logicRef.properties[property.property]
      ? "check_box"
      : "check_box_outline_blank"
      }}</mat-icon>
    <span>{{ property.label | translate }}</span>
  </button>
</mat-menu>
<div class="node-inputs" *ngIf="!isInForm">
  <app-node-port [type]="portIn" [color]="backgroundColor" [connections]="connectedToIn" [nodeRef]="this" #inputNode
    *ngIf="!_isInitialNode" (pointerdown)="emitDragStart($event, portIn, -1, connectedToIn[0])"
    (pointerup)="emitDragEnd(portIn, -1)" [attr.aria-label]="'aria.label.nodeInputPort'| translate"></app-node-port>
</div>
<app-node-content [style.backgroundColor]="backgroundColor" [logicRef]="logicRef" [processNodeRef]="processNodeRef"
  [context]="context" [outOfView]="outOfView" [isInForm]="isInForm" *ngIf="!_isSubprocessNode"></app-node-content>
<app-subprocess-content *ngIf="_isSubprocessNode && !outOfView" [lockEdit]="lockEdit" [processNodeRef]="processNodeRef"
  [zoomLevel]="zoomLevel"></app-subprocess-content>
<div class="node-outputs" *ngIf="!isInForm">
  <app-node-port [type]="portOut" [color]="backgroundColor" [connections]="[outConnection || null]"
    [processNodeRef]="processNodeRef" [label]="outputNames[i] || ''"
    *ngFor="let outConnection of connectedToOut; index as i"
    (pointerdown)="emitDragStart($event, portOut, i, outConnection)" (pointerup)="emitDragEnd(portOut, i)"
    #outputNodes [attr.aria-label]="'aria.label.nodeOutputPort'| translate"></app-node-port>
</div>
<div class="trigger-settings" *ngIf="_isInitialNode && !nodeOperationsService?.sketchManager?.isTemplate">
  <button mat-icon-button [matTooltip]="'Prozesstrigger einstellen'" [attr.aria-label]="'aria.label.setProcessTrigger'| translate" (pointerdown)="
      $event.preventDefault();
      $event.stopPropagation();
      addProcessStartTrigger()
    ">
    <mat-icon [style.color]="backgroundColor">add_box</mat-icon>
  </button>
</div>
<div class="besides-node" *ngIf="!outOfView && !isInForm">
  <div class="assigned-bubbles" *ngIf="
      this.logicRef?.constructor.shouldHaveAssigned ||
      this.logicRef?.constructor.canHaveAssigned
    ">
    <app-assigned-settings [assignedTarget]="processNodeRef"></app-assigned-settings>
  </div>
</div>
