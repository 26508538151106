<div class="header-line">
  <div class="tabs">
    <button mat-button class="tab" [class.active]="activeView === 'notifications'" (click)="view('notifications')">
      <mat-icon>notifications</mat-icon>{{ "lawyer.dashboard.notifications.title" | translate }}
    </button>
    <button mat-button class="tab" [class.active]="activeView === 'tasks'" (click)="view('tasks')">
      <mat-icon>task_alt</mat-icon>{{ "lawyer.dashboard.notifications.tasks" | translate }}
    </button>
  </div>
  <button mat-button class="mark-all" *ngIf="activeView === 'notifications'" (click)="markAllAsRead()"
    [title]="'lawyer.dashboard.notifications.markAllAsRead' | translate"
  >
    <mat-icon>done_all</mat-icon>
    <span class="mark-all-text">{{ "lawyer.dashboard.notifications.markAllAsRead" | translate }}</span>
  </button>
  <mat-paginator [length]="notificationPagination.total" [pageSize]="notificationPagination.pageSize"
    [hidePageSize]="true" (page)="onNotificationPagination($event)"
    [style.display]="activeView === 'notifications' ? null : 'none'"></mat-paginator>
  <mat-paginator [length]="eventsPagination.total" [pageSize]="eventsPagination.pageSize" [hidePageSize]="true"
    (page)="onEventsPagination($event)" [style.display]="activeView === 'tasks' ? null : 'none'"></mat-paginator>
</div>
<div class="notifications" [class.expanded]="expanded">
  <ng-container *ngIf="activeView === 'notifications'">
    <app-search-breadcrumbs [filters]="notificationFilters" (filtersChanged)="filtersChanged()"
      [availableFilters]="availableNotificationFilters"></app-search-breadcrumbs>
    <div *ngIf="service.notifications.length <= 0 && !loadingNotifications" class="info-text">
      {{ "lawyer.dashboard.notifications.noNewNotifications" | translate }}
    </div>
    <div class="loading-ind" *ngIf="loadingNotifications">
      <mat-spinner [diameter]="40"></mat-spinner>
      <span>{{ "common.label.loading" | translate }}</span>
    </div>
    <ng-scrollbar>
      <div class="notification" *ngFor="let notification of service.notifications"
        (click)="viewNotification(notification)" tabindex="0" (keydown.enter)="viewNotification(notification)">
        <mat-icon [ngSwitch]="notification.type">
          <ng-container *ngSwitchCase="'NEW_MESSAGE'">email</ng-container>
          
          <ng-container *ngSwitchCase="'NEW_MENTION'">
            <ng-container *ngIf="notification?.meta?.questionType !== 'file'; else fileIcon">edit_square</ng-container>
            <ng-template #fileIcon>upload</ng-template>
          </ng-container>
          
          <ng-container *ngSwitchCase="'PARTICIPANT_ADDED'">person_add</ng-container>
          <ng-container *ngSwitchCase="'PARTICIPANT_REMOVED'">person_remove</ng-container>
          <ng-container *ngSwitchCase="'FILE_UPDATED'">file_copy</ng-container>
          
          <ng-container *ngSwitchDefault>notifications</ng-container>
        </mat-icon>
        <div class="message-info">
          <span class="title">
            <ng-container [ngSwitch]="notification.type">
          
              <!-- NEW_MESSAGE case -->
              <ng-container *ngSwitchCase="'NEW_MESSAGE'">
                {{
                  "lawyer.dashboard.notifications.newMessage"
                  | translate: { sender: parseSender(notification) }
                }}
              </ng-container>
          
              <!-- NEW_MENTION cases -->
              <ng-container *ngSwitchCase="'NEW_MENTION'">
                <ng-container [ngSwitch]="notification?.meta?.questionType">
                  
                  <!-- NEW_MENTION with no form and no file -->
                  <ng-container *ngSwitchDefault>
                    {{
                      "lawyer.dashboard.notifications.newMention" | translate
                    }}
                  </ng-container>
          
                  <!-- NEW_MENTION with form -->
                  <ng-container *ngSwitchCase="'form'">
                    {{
                      "lawyer.dashboard.notifications.newForm" | translate
                    }}
                  </ng-container>
          
                  <!-- NEW_MENTION with file -->
                  <ng-container *ngSwitchCase="'file'">
                    {{
                      "lawyer.dashboard.notifications.fileRequested" | translate
                    }}
                  </ng-container>
          
                </ng-container>
              </ng-container>
          
              <!-- PARTICIPANT_ADDED case -->
              <ng-container *ngSwitchCase="'PARTICIPANT_ADDED'">
                {{
                  "lawyer.dashboard.notifications.participantAdded" | translate
                }}
              </ng-container>
          
              <!-- PARTICIPANT_REMOVED case -->
              <ng-container *ngSwitchCase="'PARTICIPANT_REMOVED'">
                {{
                  "lawyer.dashboard.notifications.participantRemoved" | translate
                }}
              </ng-container>
          
              <!-- FILE_UPDATED case -->
              <ng-container *ngSwitchCase="'FILE_UPDATED'">
                {{
                  "lawyer.dashboard.notifications.fileUpdated" | translate
                }}
              </ng-container>
          
              <!-- Default case (optional) -->
              <ng-container *ngSwitchDefault>
                {{
                  "lawyer.dashboard.notifications.unknownNotification" | translate
                }}
              </ng-container>
          
            </ng-container>
          </span>
          <span>
            {{ notification.send_at.slice(0, notification.send_at.lastIndexOf(':'))  }} in "{{ notification.stateName }}"</span>
        </div>
        <div class="message-actions">
          <button mat-icon-button (click)="$event.stopPropagation(); markAsRead(notification.id)" [matTooltip]="
              'lawyer.dashboard.notifications.markAsRead' | translate
            ">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </ng-scrollbar>
  </ng-container>
  <ng-container *ngIf="activeView === 'tasks'">
    <app-search-breadcrumbs [filters]="taskFilters" [availableFilters]="availableTaskFilters"
      (filtersChanged)="getTasks()"></app-search-breadcrumbs>
    <div *ngIf="!tasks?.length && !loadingNotifications" class="info-text">
      {{ "lawyer.dashboard.notifications.noNewNotifications" | translate }}
    </div>
    <div class="loading-ind" *ngIf="loadingNotifications">
      <mat-spinner [diameter]="40"></mat-spinner>
      <span>{{ "common.label.loading" | translate }}</span>
    </div>
    <ng-scrollbar *ngIf="tasks.length">
      <div class="task" *ngFor="let task of tasks" (click)="viewNotification(task)" tabindex="0"
        (keydown.enter)="viewNotification(notification)">
        <div class="task-content">
          <div [innerHTML]="task.parsedContent | sanitizeHtml"></div>
          <span>In {{ task.stateName }}</span>
        </div>
        <div class="due-at" [class.warn]="!!task.dueAtWarning">
          <mat-icon *ngIf="task.dueAtWarning">
            warning
          </mat-icon>
          <ng-container *ngIf="task.dueAt">
            {{ task.dueAt | date: 'dd.MM.YYYY'}}
          </ng-container>
          <ng-container *ngIf="!task.dueAt">
            ---
          </ng-container>
        </div>
      </div>
    </ng-scrollbar>
  </ng-container>
</div>
<button class="expand-button" mat-button *ngIf="service.notifications.length > 0 && !loadingNotifications"
  (click)="expanded = !expanded">
  <mat-icon>expand_more</mat-icon>
</button>