<button class="profile-chip handbook" [matMenuTriggerFor]="supportMenu" [matTooltip]="'profile.helpSupport' | translate"
  [attr.aria-label]="'aria.label.helpAndSupport' | translate">
  <mat-icon aria-hidden="true">question_mark</mat-icon>
</button>
<button class="profile-chip" *ngIf="auth.loggedIn" [matMenuTriggerFor]="loginOptionsMenu"
  [attr.aria-label]="'aria.label.userProfileMenu' | translate">
  <div class="user-icon" aria-hidden="true" *ngIf="!auth.isGuest">
    <mat-icon>person</mat-icon>
  </div>
  <span class="user-name" *ngIf="!auth.isGuest && (auth.userName || auth.userMail)">{{auth.userName ??
    auth.userMail}}</span>
  <div class="separator" aria-hidden="true" *ngIf="!auth.isGuest"></div>
  <mat-icon aria-hidden="true">menu</mat-icon>
</button>
<button mat-button class="login-button" *ngIf="!auth.loggedIn && !!realm?.length" (click)="showLogin = true"
  aria-label="Login">
  <mat-icon aria-hidden="true">login</mat-icon>
  <span>{{ 'common.login.title' | translate }}</span>
</button>
<mat-menu class="login-menu-global" #supportMenu="matMenu">
  <button mat-menu-item (click)="openHandbook()" [attr.aria-label]="'aria.label.openHandbook' | translate">
    <mat-icon aria-hidden="true">menu_book</mat-icon>
    {{ 'profile.handbook' | translate }}
  </button>
  <button mat-menu-item (click)="reportError()" *ngIf="auth.loggedIn"
    [attr.aria-label]="'aria.label.reportAnError' | translate">
    <mat-icon aria-hidden="true">crisis_alert</mat-icon>
    {{ 'profile.reportError' | translate }}
  </button>
  <button mat-menu-item (click)="giveFeedback()" *ngIf="auth.loggedIn"
    [attr.aria-label]="'aria.label.giveFeedback' | translate">
    <mat-icon aria-hidden="true">how_to_vote</mat-icon>
    {{ 'profile.giveFeedback' | translate }}
  </button>
  <button mat-menu-item (click)="supportHelp()" *ngIf="auth.loggedIn && !auth.isClient && realm !== ADVD_TENANT_NAME"
    [attr.aria-label]="'aria.label.getSupportHelp' | translate">
    <mat-icon aria-hidden="true">question_mark</mat-icon>
    {{ 'profile.supportHelp' | translate }}
  </button>
</mat-menu>
<mat-menu #loginOptionsMenu="matMenu" class="login-menu-global">
  <button mat-menu-item (click)="goToProfile()" [attr.aria-label]="'aria.label.goToProfile' | translate"
    *ngIf="!auth.isGuest">
    <mat-icon aria-hidden="true">person</mat-icon>
    {{ 'profile.title' | translate }}
  </button>
  <button mat-menu-item [matMenuTriggerFor]="languageMenu" [attr.aria-label]="'aria.label.selectLanguage' | translate">
    <span [ngClass]="[
     'fi',
      'fi-' +
       (translator.currentLang === 'en' ? 'gb' : translator.currentLang) ]" aria-hidden="true"></span>
    <span>{{ 'profile.language' | translate}}: {{ translator.currentLang | uppercase }}</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="supportMenu" class="mobile-only-handbook"
    [attr.aria-label]="'aria.label.helpAndSupport' | translate">
    <mat-icon aria-hidden="true">question_mark</mat-icon>
    <span>{{ 'profile.helpSupport' | translate}}</span>
  </button>
  <hr role="separator" aria-orientation="horizontal" *ngIf="!auth.isGuest" />
  <button mat-menu-item (click)="auth.logOut()" aria-label="Logout" *ngIf="!auth.isGuest">
    <mat-icon aria-hidden="true">logout</mat-icon>
    {{ 'common.button.logout' | translate }}
  </button>
</mat-menu>
<mat-menu #languageMenu="matMenu">
  <ng-container *ngFor="let language of languages">
    <button mat-menu-item [disabled]="language === translator.currentLang" (click)="selectLanguage(language)"
      [attr.aria-label]="('aria.label.select' | translate) + ' ' + (language | uppercase)">
      <span [ngClass]="['fi', 'fi-' + (language === 'en' ? 'gb' : language)]" aria-hidden="true"></span>
      {{ language | uppercase }}
    </button>
  </ng-container>
</mat-menu>

<app-keycloak-overlay mode="login" *ngIf="showLogin" (close)="showLogin = false"
  (login)="showLogin = false"></app-keycloak-overlay>