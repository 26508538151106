import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {
  FormattedDataAnswer,
  INSERTED_PLACEHOLDER_CLASS,
  getPendingFile,
  initializeFormattedAnswers,
  isGeneratedDocument,
} from 'advoprocess';
import { compare } from 'fast-json-patch';
import { FileInfo, FilesService } from 'src/api';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { ProcessService } from 'src/app/views/process/process.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { switchMap, takeUntil, timer } from 'rxjs';
import DataStore from 'advoprocess/lib/parser/data-store';
import { VideoProgressBarHandler, videoProgressTimePipe } from './video-progress.pipe';
import { DestroyNotifier } from 'src/app/common/destroy-notifier';

export interface FileOverlayDialogConfig {
  file?: FileInfo & { url?: string };
  service?: ProcessService;
}

export enum FileType {
  IMAGE = 'image',
  PDF = 'pdf',
  DOC = 'doc',
  VIDEO = 'video',
  OTHER = 'other',
}

@Component({
  selector: 'app-file-overlay-dialog',
  templateUrl: './file-overlay-dialog.component.html',
  styleUrls: ['./file-overlay-dialog.component.scss'],
  providers: [videoProgressTimePipe]
})
export class FileOverlayDialogComponent extends DestroyNotifier implements OnInit {
  @ViewChild('wordPreview') wordPreviewDiv: ElementRef<HTMLDivElement>;

  fileUrl: SafeResourceUrl;

  originalParsedDocument: { value: string; options?: any };
  parsedDocument: { value: string; options?: any };

  highlightDocumentAnswer?: string = undefined;

  formattedData: FormattedDataAnswer[] = [];

  fileType: FileType = FileType.OTHER;

  error?: HttpErrorResponse = undefined;

  documentDataStore: DataStore = undefined;

  imageRotation = 0;
  zoom = 1;
  crossHairPosition = [0, 0];
  imageOffset = [0, 0];

  canWrite = false;

  fileExists: boolean = true;

  /** Stuff for videos **/
  @ViewChild('videoPreview', { static: false }) videoPreview: ElementRef<HTMLVideoElement>;
  videoProgress: number;
  videoBuffering: boolean = true;
  progressBarHandler: VideoProgressBarHandler;

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<FileOverlayDialogComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: FileOverlayDialogConfig,
    public auth: AuthService,
    private sanitizer: DomSanitizer,
    private files: FilesService,
    private translator: TranslateService,
    private activatedRoute: ActivatedRoute,
    private el: ElementRef,
    private httpClient: HttpClient
  ) {
    super();
    if (
      !this.data.file.mime ||
      (this.data.file?.mime === 'generated' &&
        !this.data.file?.uuid.includes('PENDING'))
    ) {
      this.files
        .getFile({
          fileid: this.data.file.uuid,
          format: 'raw',
        })
        .subscribe({
          next: async (data) => {
            try {
              const parsed = JSON.parse(await data.text());
              if (parsed?.content && parsed?.options) {
                this.parsedDocument = {
                  value: parsed.content,
                  options: parsed.options,
                };
                if (this.data.service) {
                  this.formattedData = initializeFormattedAnswers(
                    this.data.service.executionState.dataStore,
                    this.auth.jwtToken$.value
                  );
                  this.documentDataStore =
                    this.data.service?.executionState?.dataStore;
                }
                this.originalParsedDocument = _.cloneDeep(this.parsedDocument);
                this.prepareDocument();
              }
            } catch {
              this.initLink();
            }
          },
          error: (error: HttpErrorResponse) => {
            if (error.status === 404) {
              this.fileExists = false;
            } else {
              this.error = error;
            }
          },
        });
    } else {
      this.fileType = this.guessFileType();
      this.initLink();
    }
    if (
      _.isNil(this.data?.file?.can_write) &&
      this.data?.service?.files?.length
    ) {
      this.canWrite =
        this.data.service.files.find((f) => f.uuid === this.data?.file?.uuid)
          ?.can_write ?? false;
    } else {
      this.canWrite = this.data?.file?.can_write ?? false;
    }
  }

  ngOnInit(): void {
    if (this.fileType === FileType.VIDEO) {
      this.progressBarHandler = new VideoProgressBarHandler();
      setTimeout(() => this.initVideoControls(), 0);
    }
  }

  private initVideoControls() {
    this.progressBarHandler.video = this.videoPreview.nativeElement;
    this.videoPreview.nativeElement.addEventListener('timeupdate', () => {
      this.videoProgress = this.videoPreview.nativeElement.currentTime / this.videoPreview.nativeElement.duration * 100;
    });
    this.progressBarHandler.positionChange$.pipe(takeUntil(this.destroy$)).subscribe((pos) => {
      this.videoProgress = pos * 100;
    });
    timer(50, 50).pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.videoBuffering = this.videoPreview.nativeElement.readyState < this.videoPreview.nativeElement.HAVE_FUTURE_DATA;
    })
  }

  public fullscreenVideo() {
    this.videoPreview?.nativeElement?.requestFullscreen();
  }

  private initLink() {
    let rawUrl: string;
    if (this.data.file.url) {
      rawUrl = this.data.file.url;
    } else if (this.data.file.uuid.includes('<<<PENDING')) {
      const file = getPendingFile(this.data.file.uuid);
      if (isGeneratedDocument(file.file)) {
        this.parsedDocument = {
          value: file.file.content,
          options: file.file.options,
        };
        if (this.data.service) {
          this.formattedData = initializeFormattedAnswers(
            this.data.service.parser.thread.dataStore,
            this.auth.jwtToken$.value
          );
        }
        this.originalParsedDocument = _.cloneDeep(this.parsedDocument);
        this.prepareDocument();
        return;
      } else {
        rawUrl = getPendingFile(this.data.file.uuid)?.url;
      }
    } else {
      const realm =
        this.activatedRoute?.firstChild?.snapshot?.paramMap?.get('realm') ?? '';
      rawUrl =
        environment.API_URL +
        '/file/' +
        this.data.file.uuid +
        '?' +
        (this.auth.loggedIn
          ? 'adv_tk_gen=' + this.auth.jwtToken$.value + '&'
          : '') +
        'realm=' +
        realm;
    }
    this.setAutomaticZoom();
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(rawUrl);
    if (this.fileType === FileType.DOC) {
      setTimeout(() => this.initDocxPreview(), 50);
    }

    //Verify that linked file actually exists
    if (rawUrl?.length && !this.data?.file?.uuid?.includes('<<<PENDING')) {
      this.httpClient.head(rawUrl, { headers: {} }).subscribe({
        error: () => {
          this.fileExists = false;
        }
      })
    }
  }

  private setAutomaticZoom() {
    if (
      this.fileType === FileType.IMAGE ||
      this.fileType === FileType.PDF ||
      !!this.parsedDocument
    ) {
      // Set the zoom size to something reasonable according to the current screen size
      // 1200px is just an arbitrary value that looked good while testing
      this.zoom = Math.min(1, Math.max(window.innerWidth / 1200, 0.2));
    }
  }

  private initDocxPreview() {
    if (!this.wordPreviewDiv?.nativeElement) return;
    import('docx-preview').then(({ renderAsync }) => {
      this.files
        .getFile({
          fileid: this.data.file.uuid,
        })
        .pipe(
          switchMap((fileData) =>
            renderAsync(fileData, this.wordPreviewDiv.nativeElement)
          )
        )
        .subscribe(() => { });
    });
  }

  prepareDocument(): void {
    window.setTimeout(() => {
      document
        .querySelectorAll(`app-document .${INSERTED_PLACEHOLDER_CLASS}`)
        .forEach((t) => {
          if (
            (t as HTMLElement).innerText
              .replace(/\r?\n/gm, '')
              .match(/^[\s]*$/gm) &&
            !Boolean(t.querySelector('img'))
          ) {
            t.setAttribute(
              'data-empty',
              this.translator.instant('client.process.emptyMessage')
            );
          }
          t.addEventListener('pointerup', (event) => {
            event.stopPropagation();
            this.highlightDocumentAnswer = JSON.parse(
              t.getAttribute('data-tagid')
            )[0];
            this.showHighlightTransition(event.target as HTMLElement);
          });
        });
      document
        .querySelector('app-document .jodit-wysiwyg')
        .addEventListener('keyup', () => {
          document
            .querySelectorAll(
              `app-document .${INSERTED_PLACEHOLDER_CLASS}[data-empty]`
            )
            .forEach((t) => {
              if (
                !(t as HTMLElement).innerText
                  .replace(/\r?\n/gm, '')
                  .match(/^[\s]*$/gm) ||
                Boolean(t.querySelector('img'))
              ) {
                t.removeAttribute('data-empty');
              }
            });
        });
      document
        .querySelector('app-document')
        .addEventListener('pointerup', () => {
          this.highlightDocumentAnswer = undefined;
        });

      this.setAutomaticZoom();
    }, 500);
  }

  saveDocument() {
    if (!this.data?.file.uuid.includes('PENDING')) {
      this.files
        .updateFile({
          fileid: this.data.file.uuid,
          fileUpdateContent: {
            file_content: compare(
              {
                content: this.originalParsedDocument.value,
                options: this.originalParsedDocument.options,
              },
              {
                content: this.parsedDocument.value,
                options: this.parsedDocument.options,
              }
            ),
          },
        })
        .subscribe(() => {
          this.originalParsedDocument = _.cloneDeep(this.parsedDocument);
        });
    } else {
      const file = getPendingFile(this.data.file.uuid);
      if (isGeneratedDocument(file.file)) {
        file.file.content = this.parsedDocument.value;
        file.file.options = this.parsedDocument.options;
      }
    }
    this.dialogRef.close();
  }

  private showHighlightTransition(startElement: HTMLElement) {
    const d = document.createElement('div');
    d.className = 'transition-ind';
    const rect = startElement.getBoundingClientRect();
    d.style.top = `${rect.y}px`;
    d.style.left = `${rect.x}px`;
    d.style.width = `${rect.width}px`;
    d.style.height = `${rect.height}px`;
    const target = document.querySelector(
      `.document-overlay .answers-shortview-table tr[data-answer-name="${this.highlightDocumentAnswer}"]`
    );
    document.body.appendChild(d);
    setTimeout(() => {
      const rect = target.getBoundingClientRect();
      d.style.top = `${rect.y}px`;
      d.style.left = `${rect.x}px`;
      d.style.width = `${rect.width}px`;
      d.style.height = `${rect.height}px`;
    }, 0);
    setTimeout(() => {
      d.remove();
    }, 500);
  }

  private guessFileType(): FileType {
    const mime = this.data.file.mime;
    if (mime.startsWith('image/')) {
      return FileType.IMAGE;
    }
    if (mime.startsWith('video/')) {
      return FileType.VIDEO;
    }
    if (mime.includes('pdf')) {
      return FileType.PDF;
    }
    if (
      mime.includes(
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      )
    ) {
      return FileType.DOC;
    }
    return FileType.OTHER;
  }

  rotateImage() {
    this.imageRotation = (this.imageRotation + 90) % 360;
  }

  zoomIn() {
    this.zoom = Math.min(this.zoom + 0.1, 6);
  }

  zoomOut() {
    this.zoom = Math.max(this.zoom - 0.1, 0.2);
  }

  wheelZoom(event: WheelEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.zoom = Math.max(Math.min(6, this.zoom - event.deltaY / 400), 0.2);
  }

  eventCache: PointerEvent[] = [];
  prevPos = null;
  prevDiff = -1;

  pointerDown(event: PointerEvent) {
    event.preventDefault();
    this.eventCache.push(event);
  }

  updateFocusInfo(event: PointerEvent) {
    event.stopPropagation();
    event.preventDefault();
    const parentRect = (event.target as HTMLElement).getBoundingClientRect();
    this.crossHairPosition = [
      Math.round(event.clientX - parentRect.left),
      Math.round(event.clientY - parentRect.top),
    ];
    for (let i = 0; i < this.eventCache.length; i++) {
      if (event.pointerId === this.eventCache[i].pointerId) {
        this.eventCache[i] = event;
        break;
      }
    }
    if (this.eventCache.length) {
      const curX = this.eventCache[0].clientX;
      const curY = this.eventCache[0].clientY;
      if (this.eventCache.length === 2) {
        const curDiff = Math.sqrt(
          Math.pow(this.eventCache[0].clientX - this.eventCache[1].clientX, 2) +
          Math.pow(this.eventCache[0].clientY - this.eventCache[1].clientY, 2)
        );
        if (this.prevDiff > 0) {
          const targetLevel = Math.max(
            0.2,
            Math.min(this.zoom * (curDiff / this.prevDiff), 6)
          );
          this.zoom = targetLevel;
        }
        this.prevDiff = curDiff;
      }
      if (this.prevPos) {
        const dx = (curX - this.prevPos.x) / this.zoom;
        const dy = (curY - this.prevPos.y) / this.zoom;
        this.imageOffset[0] += dx;
        this.imageOffset[1] += dy;
      }
      this.prevPos = { x: curX, y: curY };
    }
    this.limitImagePosition();
  }

  private limitImagePosition() {
    const rectImage = (
      this.el.nativeElement.querySelector('.image-wrapper') as HTMLDivElement
    )?.getBoundingClientRect();
    const parentImage = this.el.nativeElement
      .querySelector('.image-viewer')
      ?.getBoundingClientRect();
    if (!rectImage || !parentImage) return;
    const maxX = Math.max(
      (0.5 * (rectImage.width - parentImage.width)) / this.zoom,
      0
    );
    const maxY = Math.max(
      (0.5 * (rectImage.height - parentImage.height)) / this.zoom,
      0
    );
    this.imageOffset[0] = Math.max(-maxX, Math.min(maxX, this.imageOffset[0]));
    this.imageOffset[1] = Math.max(-maxY, Math.min(maxY, this.imageOffset[1]));
  }

  pointerUp(event: PointerEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.eventCache = [];
    this.prevPos = null;
    this.prevDiff = -1;
  }

  downloadFile() {
    const a = document.createElement('a');
    a.href = this.fileUrl['changingThisBreaksApplicationSecurity'];
    a.target = '_blank';
    a.setAttribute('download', this.data?.file?.name ?? '');
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
}
