/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WebHookLogEntry } from './webHookLogEntry';


export interface WebHookLogs { 
    logs?: Array<WebHookLogEntry>;
    total_entries?: number;
}

