<ng-container *ngIf="!detailUser; else detailView">
  <div class="tabs" role="tablist">
    <div class="tab" role="tab" [attr.aria-selected]="activeView === 'clients'" [attr.aria-controls]="'clients-panel'" [class.active]="activeView === 'clients'" (click)="activeView = 'clients'" tabindex="0"
      (keyup.enter)="activeView = 'clients'" *ngIf="canRead('clients')" aria-controls="clients-panel">
      <mat-icon aria-hidden="true">people</mat-icon>
      <span>{{ "lawyer.dashboard.clients.externalContacts" | translate }}</span>
    </div>
    <div class="tab" [class.active]="activeView === 'lawyers'" (click)="activeView = 'lawyers'" (keyup.enter)="activeView = 'lawyers'" *ngIf="canRead('lawyers')"  tabindex="0"
         role="tab" [attr.aria-selected]="activeView === 'lawyers'" [attr.aria-controls]="'lawyers-panel'">
      <mat-icon aria-hidden="true">engineering</mat-icon>
      <span>{{ "lawyer.dashboard.clients.internalContacts" | translate }}</span>
    </div>
    <div class="tab" role="tab" [attr.aria-selected]="activeView === 'groups'" [attr.aria-controls]="'groups-panel'" [class.active]="activeView === 'groups'" tabindex="0" (click)="activeView = 'groups'"
      (keyup.enter)="activeView = 'groups'"
      *ngIf="canRead('groups') && commonService.checkFeatureFlagSync('groups.enabled')">
      <mat-icon aria-hidden="true">groups_2</mat-icon>
      <span>{{ "lawyer.dashboard.clients.groups" | translate }}</span>
    </div>
  </div>
  <ng-scrollbar>
    <div class="view-contents-container">
      <div class="view-contents" id="clients-panel" role="tabpanel" tabindex="0" *ngIf="activeView === 'clients'" [@fadeAnimation]>
        <div class="sketch-actions">
          <button mat-button (click)="addClient()" [attr.aria-label]="'aria.label.addNewClient' | translate">
            <mat-icon class="button-icon" aria-hidden="true">add</mat-icon>
            <span>{{ "lawyer.dashboard.clients.new" | translate }}</span>
          </button>
          <!-- <button mat-button (click)="exportClientData()">
            <mat-icon class="button-icon">import_export</mat-icon>
            <span>{{ "lawyer.dashboard.clients.export" | translate }}</span>
          </button> -->
        </div>
        <app-table [config]="externalTableConfig" *ngIf="externalTableConfig" #tableRef></app-table>
      </div>
      <div class="view-contents" id="lawyers-panel" role="tabpanel" tabindex="0" *ngIf="activeView === 'lawyers'" [@fadeAnimation]>
        <div class="sketch-actions">
          <button mat-button (click)="addLawyer()" [attr.aria-label]="'aria.label.addNewLawyer' | translate">
            <mat-icon class="button-icon" aria-hidden="true">add</mat-icon>
            <span>{{
              "lawyer.dashboard.clients.newInternal" | translate
              }}</span>
          </button>
        </div>
        <app-table [config]="internalTableConfig" *ngIf="internalTableConfig" #tableRef></app-table>
      </div>
      <div class="view-contents" id="groups-panel" role="tabpanel" tabindex="0" *ngIf="activeView === 'groups'" [@fadeAnimation]>
        <div class="sketch-actions">
          <button mat-button (click)="addGroup()" [attr.aria-label]="'aria.label.addNewGroup' | translate">
            <mat-icon class="button-icon" aria-hidden="true">add</mat-icon>
            <span>{{ "lawyer.dashboard.clients.newGroup" | translate }}</span>
          </button>
        </div>
        <app-table [config]="groupTableConfig" *ngIf="groupTableConfig" #tableRef></app-table>
      </div>
    </div>
  </ng-scrollbar>
</ng-container>
<ng-template #detailView>
  <app-user-edit [user]="detailUser"></app-user-edit>
</ng-template>
