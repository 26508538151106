<ng-scrollbar>
  <div class="sketch-actions" role="toolbar" [attr.aria-label]="'aria.label.dossierActions' | translate">
    <button mat-button (click)="newDossier()" [attr.aria-label]="'aria.label.createNewDossier' | translate">
      <mat-icon class="button-icon" aria-hidden="true">add</mat-icon>
      <span>{{ "lawyer.dashboard.dossiers.new" | translate }}</span>
    </button>
    <app-search-menu [entries]="queryProcesses.bind(this)" (entrySelected)="addFromProcess($event)"
      *ngIf="permissions.canRead('legal_processes') && commonService.checkFeatureFlagSync('processes.enabled')">
      <button mat-button [attr.aria-label]="'aria.label.selectFromProcess' | translate">
        <mat-icon class="button-icon" aria-hidden="true">account_tree</mat-icon>
        <span>{{ "lawyer.dashboard.dossiers.fromProcess" | translate }}</span>
      </button>
    </app-search-menu>
  </div>
  <app-table [config]="tableConfig" *ngIf="tableConfig" #tableRef [attr.aria-label]="'aria.label.dossierTable' | translate"></app-table>
</ng-scrollbar>
