const UPLOAD_SIZE_MB = 500;
export const MAX_UPLOAD_SIZE = UPLOAD_SIZE_MB * 1024 * 1024; // in bytes
export const MAX_UPLOAD_SIZE_HUMAN_READABLE = UPLOAD_SIZE_MB + 'MB';

export const ALLOWED_FILE_FORMATS = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'video/mkv'
]

export const ALLOWED_FILE_STRING = ALLOWED_FILE_FORMATS.join(', ');